<template>
	<div class="fill-height fill-width">
		<v-row>
			<v-col>
				<div class="headline mb-1 text-center">Policies</div>
			</v-col>
		</v-row>
		<v-card class="mt-4 mx-1" flat>
			<v-expansion-panels>
				<v-expansion-panel
					v-for="(item,i) in supportives"
					:key="i"
				>
					<v-expansion-panel-header :class="item.deleted_at ? 'opacity-5' : ''">
						<div class="body-1 font-weight-bold">{{ item.name }}</div>
					</v-expansion-panel-header>
					<v-expansion-panel-content class="pa-0">
						<div
							class="display-paragraph px-2 py-2 overflow-auto radius-05" 
							style="border: 1px solid #AAA;"
							v-html="item.content"
						>
						</div>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
	</div>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"

const DEFAULT_IMAGE_FILE_TYPE_ID = 11;

export default {
	components:{
	},
	filters: {
		replace: function (st, rep, repWith) {
			const result = st.split(rep).join(repWith)
			return result;
		}
	},
	mixins: [
		resourceMixin,
		errorHandlerMixin,
	],
	watch:{
		'type':
		{
			handler(newValue, oldValue) 
			{
				if(newValue)
					this.initialize_()
				else	
					this.type = oldValue
			}
		}
	},
	data(){
		return {
			errors: {},
			fetch_loading: false,
			type: "tnc",
			form: {},
			supportives: [
				{
					name: "Terms & Conditions",
					value: "tnc",
					content: ""
				},
				{
					name: "Privacy Policy",
					value: "privacy-policy",
					content: ""
				},
				{
					name: "Refunds & Returns",
					value: "refund-policy",
					content: ""
				},
				{
					name: "Delivery Policy",
					value: "delivery-policy",
					content: ""
				},
			]
		}
	},
	computed: {
	},
	created(){
		this.initialize_()
	},
	methods:{
		async getResourceData_(page = 1){
			this.fetch_loading = true
			for(let supportive of this.supportives)
			{
				await this.$api.getSupportive(supportive.value).then((res)=>{
					let result = res.data.result[0]
					supportive.content = result?.content || ''
				}).catch((err) => {
					this.errorHandler_(err)
				}).finally(()=>{
					this.fetch_loading = false
				})
			}

		},
		submitMessage()
		{
			this.errors = {}
			let payload = {
				name: this.resourceData_.name,
				contact_number: this.resourceData_.contact_number,
				email: this.resourceData_.email,
				company_country: this.resourceData_.company_country,
				message: this.resourceData_.message,
			}

			this.model_loading_ = true
			this.$api.submitContactUs(payload).then((res)=>{
				this.$toast.success(res.data.message)
			}).catch((err)=>{
				this.errors = this.errorHandler_(err, ['name', 'contact_number', 'email', 'company_country', 'message'])
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
	},
}
</script>
<style>
.rounded-card{
    border-radius:50px;
}
</style>
